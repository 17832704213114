<template>
    <div class="content">
        <div class="content-initial">
            <div class="cabecalho">
                <p class="title flex">Eventos do Broadcast</p>
                <div class="flex">
                </div>
            </div>
        </div>
        <div>
            <div class="flex animations-btn-action espace-checkbox-event">
                <div class="flex">
                    <!-- <b-form-checkbox-group  
                        id="checkbox-group-all" 
                        v-model="status_auto2" 
                        @change="clickCheckAll"
                        class="input-check"
                        name="flavour-all"
                    >
                        <b-form-checkbox value="xx"></b-form-checkbox>
                    </b-form-checkbox-group>
                    <p class="text-total">Seleciona todos os {{ total_events }} eventos</p> -->
                </div>
                <div class="espacemento-select flex">
                    <svg  class="svg-filter" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 0.759033H1L7 8.64237V14.0924L10 15.759V8.64237L16 0.759033Z" stroke="#00E4A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <b-form-select @change="trocaEvento" v-model="event_selected" :options="options_event"></b-form-select>
                </div>
            </div>
            <b-table
                :fields="fields"
                show-empty
                small
                stacked="md"
                :items="broadcasts"
                :filter="filter"
                :tbody-tr-class="rowClass"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
            >
                <template #empty>
                    <p class="title text-center-empty">{{ emptyText }}</p>
                    </template>
                    <template #emptyfiltered>
                    <p class="title text-center-empty">{{ emptyText }}</p>
                </template>
                <template v-slot:head(lead_id)>
                    <label class="check">
                        <b-form-checkbox-group  
                            id="checkbox-group-all" 
                            v-model="status_auto2" 
                            @change="clickCheckAll"
                            class="input-check"
                            name="flavour-all"
                        >
                            <b-form-checkbox value="xx"></b-form-checkbox>
                        </b-form-checkbox-group>
                    </label>
                </template>
                <template #cell(lead_id)="row">
                    <div>
                        <b-form-checkbox-group  
                            :id="`checkbox-group-`+row.item.id" 
                            v-model="status_auto" 
                            @change="clickCheck"
                            class="input-check"
                            :name="`flavour-`+row.item.id"
                        >
                            <b-form-checkbox :value="row.item.id"></b-form-checkbox>
                        </b-form-checkbox-group>
                   </div>
                </template>
                <template #cell(lead)="row">
                     <div class="title-open fakeListen divFakeLista">{{ row.item.lead_id }}</div>
                </template>
                <template #cell(address)="row">
                     <div class="title-open fakeListen divFakeLista">{{ row.item.address }}</div>
                </template>
                <template #cell(sg_timestamp)="row">
                     <div class="title-open fakeListen divFakeLista">{{ row.item.sg_timestamp | moment("DD/MM/YYYY HH:mm") }}</div>
                </template>
                <template #cell(sg_event_id)="row">
                     <div class="title-open fakeListen divFakeLista">{{ row.item.sg_event_id }}</div>
                </template>
                <template #cell(email_id)>
                     <div class="title-open fakeListen divFakeLista">{{ event_selected_text }}</div>
                </template>
            </b-table>
            <div class="espace-pagination">
                <button @click="lastPage()" class="btn-pagination">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 6H1M1 6L6 11M1 6L6 1" stroke="#333333" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    Anterior
                </button>
                <b-pagination
                    v-model="currentPage"
                    prev-text="Anterior"
                    next-text="Próximo"
                    align="fill"
                    limit="1"
                    size="sm"
                    class="my-0"
                >
                <template #prev-text>
                    <span class="prev-text" @click="lastPage()">
                    </span>
                </template>
                <template #page="{ active }">
                    <p class="text-pagination">Página:</p><b-form-input id="input-pagination" v-if="active" v-on:blur="getList()" @keyup.enter="getList()" v-model="currentPage"></b-form-input><p class="text-pagination2">de {{ totalPage }}</p>
                </template>
                <template #next-text>
                    <span class="next-text" @click="nextPage()">
                    </span>
                </template>

                </b-pagination>
                <button @click="nextPage()" class="btn-pagination">
                    Próximo
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 6L6 11M1 6H11H1ZM11 6L6 1L11 6Z" stroke="#333333" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from '../../../node_modules/vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Detalhe from '../broadcast/ModalDetalhe';
import EmailService from '@/services/resources/EmailService';
const axios = require('axios');
const moment = require('vue-moment');
const serviceEmail =  EmailService.build();

export default {
    name: 'listagem',
    components: {
        Detalhe,
    },
    data() {
        return {
            selected: "filtros",
            options: [
                { value: 'filtros', text: 'Filtros' },
                { value: 'mes', text: 'Mês' },
            ],
            result_request:{},
            filter: null,
            access_token:'',
            status_auto: [],
            status_auto2: [],
            btnsActions: false,
            emptyText: "Sem Resultados",
            total_events:"00",
            broadcasts: [
                {
                    id: 129395,
                    email_id: 566,
                    blog_id: 6,
                    lead_id: 79050,
                    address: "xxxxxxxxxx@xxxxxxxxxx.com.br",
                    unique_args: "{\"email_id\":566,\"lead_id\":79050}",
                    ip: null,
                    user_agent: null,
                    url: "https://blog.gdigital.com.br/category/lives-da-semana-gdigital/",
                    sg_timestamp: "2020-07-21 13:05:00",
                    sg_event_id: "legado:6:566:79050",
                    sg_message_id: null,
                    created_at: "2020-11-20T10:24:20.000000Z",
                    updated_at: "2020-11-20T10:24:20.000000Z",
                    lead: {
                    }
                }
            ],
            fields: [
                { key: 'lead_id', label: 'check'},
                { key: 'lead', label: 'Lead ID'},
                { key: 'address', label: 'E-mail'},
                { key: 'sg_timestamp', label: 'Data do Evento'},
                { key: 'email_id', label: 'Status'},
            ],
            totalPage:1,
            rowClass:'row-table-body',
            totalRows: 1,
            currentPage: 1,
            perPage: 15,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            event_selected:"delivered",
            event_selected_text:"Entregues",
            options_event: [
                { value: 'delivered', text: 'Entregues' },
                { value: 'open', text: 'Abertos' },
                { value: 'click', text: 'Clicados' },
                { value: 'spam', text: 'Spams' },
                { value: 'unsubscribed', text: 'Unsubscribeds' },
                { value: 'bounce', text: 'Bounces' },
                { value: 'dropped', text: 'Droppeds' },
                { value: 'deferred', text: 'Deferreds' },
            ],
            id_selected:"",
        }
    },
    mounted() {
            this.getEmail();
            this.getList();
            this.totalRows = this.broadcasts.length;
    },
    methods:{
        getEmail(){
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            this.id_selected = urlParams.get('email');

        },
        switchTextEvent(){
            switch (this.event_selected) {
                case "delivered":
                    this.event_selected_text = "Entregues"
                    break;
                case "open":
                    this.event_selected_text = "Abertos"
                    break;
                case "click":
                    this.event_selected_text = "Clicados"
                    break;
                case "spam":
                    this.event_selected_text = "Spams"
                    break;
                case "unsubscribed":
                    this.event_selected_text = "Unsubscribeds"
                    break;
                case "bounce":
                    this.event_selected_text = "Bounces"
                    break;
                case "dropped":
                    this.event_selected_text = "Droppeds"
                    break;
                case "deferred":
                    this.event_selected_text = "Deferreds"
                    break;
            }
        },
        lastPage(){
            if(this.currentPage === 1){
               
            }else{
                this.currentPage = this.currentPage-1
                this.getList();
            }
        },
        actionDeletar(){
            console.log(this.status_auto);
        },
        actionClonar(){
            console.log(this.status_auto);
        },
        trocaEvento(){
             this.getList();
        },
        clickCheck(){
            this.verificaBtn();
        },
        clickCheckAll(){
            if(this.status_auto.length === 0){
                let broadcasts = this.broadcasts;
                let array = [];
                for (let i = 0; i < broadcasts.length; i++) {
                    const element = broadcasts[i].id;
                    array.push(element);
                }
                this.status_auto = array;
                this.verificaBtn();
            }else{
                this.status_auto = [];
                this.status_auto2 = [];
                this.verificaBtn();
            }
        },
        verificaBtn(){
            if(this.status_auto.length !== 0){
                this.btnsActions = true;
            }else if(this.status_auto.length === 0){
                this.btnsActions = false;
            }
        },
        nextPage(){
            if(this.currentPage === this.totalPage){
            }else{
                this.currentPage = this.currentPage+1
                this.getList();
            }
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            // this.currentPage = 1
        },
        selectAllbox(){
            if(this.status_auto.length === 0){
                let array = [];
                let broadcast = this.broadcasts;
                for (let i = 0; i < broadcast.length; i++) {
                    let all = broadcast[i].id;
                    array.push(all);
                }
                this.status_auto = array;
            }else{
                this.status_auto = []
            }
        },
        searchListBlur(){
            console.log("ywywywywyk", this.filter, "kywywywywy");
            if(this.filter === ""){
                this.getList();
            }else{
                this.searchList();          
            }
        },
        searchList(){
            console.log("lalalaalal", this.filter);
            if(this.filter === ""){
                this.getList();
            }else{
                serviceEmail
                .search(`busca=`+this.filter)
                .then(resp => {
                    console.log("xxxxxxserach", resp);
                    this.broadcasts = resp.data;
                    this.totalPage = resp.last_page;
                    this.currentPage = resp.current_page;
                    this.removeLoading();
                }).catch(err => {
                    console.log(err);
                });
            }
        },
        removeLoading(){
            setTimeout(function(){
                let div = document.querySelectorAll('.fakeListen');
                for (let i = 0; i < div.length; i++) {
                    div[i].classList.remove('divFakeLista');
                }
            }, 30);
        },
        getList(){
            // var config = {
            //     headers: {'Authorization': 'MTQwOTkuMTk5MTAuZTEzMWM3Mzk0YTJlMDVhMjAzOGVkOGQwNmFiY2ZlYzYwN2E5YjdlMGJmNTJmMWEzMGMzZjUwMjdhNjYxNWM2OQ=='}
            // };
            // axios.get('https://api.innovaweb.com.br/email?page='+this.currentPage, config)
            let div = document.querySelectorAll('.fakeListen');
            for (let i = 0; i < div.length; i++) {
                div[i].classList.add('divFakeLista');
            }
            serviceEmail
            .read(this.id_selected+`/event/`+this.event_selected+`?page=`+this.currentPage)
            .then(resp => {
                console.log("getlistevent", resp);
                this.broadcasts = resp.data;
                this.totalPage = resp.last_page;
                this.total_events = resp.total;
                this.currentPage = resp.current_page;
                this.removeLoading();
                this.switchTextEvent();
                
            }).catch(err => {
                console.log(err);
            });
        },
    },
}
</script>

<style scoped>
.divFakeLista {
    color: #ededf000 !important;
    border-radius: 6px;
    animation: preload .8s forwards linear infinite alternate;
    background: #ededf0;
}

@keyframes preload {
  from {
    background: #EDEDF0;
  }

  to {
    background: rgb(192, 192, 192);
  }
}


.btn-pagination:hover{
    color: #00E4A0;
}

.btn-pagination:hover svg{
    filter: invert(0.4) sepia(1) saturate(5) hue-rotate(106.2deg) brightness(1.2);
}

.btns-actions{
    border:none;
    border: 1px solid #00E4A0;
    box-sizing: border-box;
    border-radius: 3px;
    height: 38px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.2px;
    color: #00E4A0;
    margin-left: 20px;
    background-color: white;
    width: 136px;
    margin-bottom: 20px;
}

.title-open.title-espacamento {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 375px;
}

.espacemento-select{
    width: 195px;
    margin-bottom: 6px;
    position: relative;
    bottom: 4px; 
}

svg.svg-filter {
    position: relative;
    top: 10px;
    left: 20px;
}

.animations-btn-action{
  animation: animationactionsbtn linear 0.5s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  -webkit-animation: animationactionsbtn linear 0.5s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: animationactionsbtn linear 0.5s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -o-animation: animationactionsbtn linear 0.5s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -ms-animation: animationactionsbtn linear 0.5s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
}

@keyframes animationactionsbtn{
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes animationactionsbtn{
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes animationactionsbtn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes animationactionsbtn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes animationactionsbtn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.espacamento-svg-action{
    margin-right: 5px;
    margin-bottom: 3px;
}

.btn-pagination{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #333333;
    background-color: white;
    border: none;
}

.text-total{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #00E4A0;
    margin-left: 10px;

}

.number-active{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 48px;
    letter-spacing: 0.2px;
    color: #000000;
    width: 64px;
    height: 50px;
    border: 1px solid #EDEDF0;
    box-sizing: border-box;
    border-radius: 3px;
}
.text-pagination{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #333333;
    padding-top: 12px;
    padding-right: 20px;
}

p.title.text-center-empty {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 20px;
}

.espace-pagination {
    margin-top: 50px;
    margin-bottom: 100px;
    display: flex;
    justify-content: space-evenly;
}

ul.pagination.my-0.b-pagination.pagination-sm.text-center {
    padding-right: 100px;
}

.text-pagination2{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #333333;
    padding-top: 12px;
    padding-left: 10px;
    white-space: nowrap;
}

.flex.animations-btn-action.espace-checkbox-event {
    margin-left: 28px;
    justify-content: space-between;
}

.prev-text{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #333333;
}

.next-text{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #00E4A0;
}

.cursor{
    cursor: pointer;
}

.hide{
    display: none !important;
}

.check {
    padding-left: 4px;
    cursor: pointer;
    position: relative;
    top: 13px;
}

.content-clicados{
    width: 78px;
    height: 32.73px;
    background: rgba(46, 215, 238, 0.1);
    border-radius: 5px;
    justify-content: space-evenly;
    display: flex;
    margin-top: 10px;
}

.filter-espace{
    margin-top: 10px;
    width: 120px;
}

.pointer-clicados{
    background:#2ED7EE;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-top: auto;
    margin-bottom: auto;
}

.value-clicados{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color:#2ED7EE;
    margin-top: -9px;
}

.content-abertos{
    width: 78px;
    height: 32.73px;
    background: rgba(255, 203, 55, 0.1);
    border-radius: 5px;
    justify-content: space-evenly;
    display: flex;
    margin-top: 10px;
}

span.checkmark {
    background: #EDEDF0;
    border: 1px solid #B5B9C5;
    box-sizing: border-box;
    border-radius: 2px;
}

.pointer-abertos{
    background: #FFCB37;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-top: auto;
    margin-bottom: auto;
}

.value-abertos{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFCB37;
    margin-top: -9px;
}

.content-entregues{
    width: 78px;
    height: 32.73px;
    background: rgba(0, 228, 160, 0.1);
    border-radius: 5px;
    justify-content: space-evenly;
    display: flex;
    margin-top: 10px;
}

.pointer-entregues{
    background: #00E4A0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-top: auto;
    margin-bottom: auto;
}

.value-entregues{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #00E4A0;
    margin-top: -9px;
}

.radius{
    border-radius: 5px;
}

.form-check-input {
    margin-left: 0px !important;
}


.div-btn{
    display: flex;
    width: 73%;
    justify-content: flex-end;
}

.table {
    width: 98%;
    margin-bottom: 1rem;
    color: #212529;
    margin-left: 20px;
}

.flex{
    display: flex;
}

button.new-broadcast {
    width: 219px;
    height: 50px;
    border: none;
    background: #00E4A0;
    border-radius: 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.2px;
	color: #fff;
	margin-top: -13px;
    position: absolute;
    right: 9px;
}

.input-search{
    margin-right: 25px;
    margin-bottom: 40px;
    background: #00e4a00d;
    border: 1px solid #00E4A0;
    box-sizing: border-box;
    border-radius: 5px;
    width: 287px;
    height: 50px;
}

.svg-data-feed{
    margin-right: 0px;
    margin-top: 9px;
    position: absolute;
    margin-left: 10px;
}

.table-responsive-sm {
    margin-left: 20px;
    width: 98%;
}

.custom-select {
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    color: #333333;
    appearance: auto;
    border: 0.5px solid #EDEDF0;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(237, 237, 240, 0.1);
    border-radius: 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    padding-left: 30px;
    align-items: center;
    background-image: none;
    padding-right: 13px;
    border: none;
}

.content{
    width: 92%;
    margin-left: 98px;
    background: #FDFCFF;  
}

.content-initial{
    width: 98%;
    display: flex;
    margin-left: 20px;
    background: #FDFCFF;  
}

.title{
   font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #333333;
    margin-bottom: 30px;
}

.cabecalho{
    margin-top: 70px;
    width: 100%;
}

input#input-pagination {
    width: 64px;
    height: 50px;
    border: 1px solid #EDEDF0;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.2px;
    color: #000;
    display: flex;
    text-align: -webkit-center;
}
</style>