<template>
    <b-container fluid class="p-0">
    <slidebar />
    <listagem />
    <wizard />
    </b-container>
</template>

<script>
import Slidebar from '@/components/broadcast/Slidebar.vue';
import Listagem from '@/components/broadcast/ListagemEvento.vue';
import Wizard from '@/components/broadcast/ModalWizard.vue';

export default {
  components: {
    Slidebar,
    Listagem,
    Wizard
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.animation-opacity{
  animation: animationFramesOpacity linear 0.3s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  -webkit-animation: animationFramesOpacity linear 0.3s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: animationFramesOpacity linear 0.3s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -o-animation: animationFramesOpacity linear 0.3s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -ms-animation: animationFramesOpacity linear 0.3s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
}

@keyframes animationFramesOpacity{
  0% {
    opacity:0.17;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes animationFramesOpacity{
  0% {
    opacity:0.17;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes animationFramesOpacity {
  0% {
    opacity:0.17;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes animationFramesOpacity {
  0% {
    opacity:0.17;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes animationFramesOpacity {
  0% {
    opacity:0.17;
  }
  100% {
    opacity:1;
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff !important;
    border-color: #00e4a0 !important;
    background-color: #00e4a0 !important;
    border-radius: 2px !important;
}

.custom-radio .custom-control-label::before {
    border-radius: 2px !important;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: none !important;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
    border: none;
    background: #f5f2fc80;
    border-radius: 5px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 27px;
    letter-spacing: 0.2px;
    color: #B5B9C5;
    width: 1%;
}

.table .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 15px !important;
    height: 15px !important;
    pointer-events: none;
    content: "";
    background-color: #ededf0 !important;
    border-radius: 2px !important;
    border: #adb5bd solid 1px;
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
    color: #000;
    opacity: inherit !important;
    position: absolute;
    z-index: 999999999999999999999;
    right: 20px;
    top: 10px;
}

.navbar {
    background: white;
    border-bottom: 1px solid lightgray;
}

.navbar-brand {
    font-size: 14px;
    color: #888;
}

.modal-open .modal {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}


.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
    color: #000;
    opacity: inherit !important;
}

span.stepTitle {
    display: none;
}

span.stepTitle.active {
    display: block !important;
}

span.stepTitle.active {
    display: block !important;
    position: relative;
    // left: 62%;
    font-family: Montserrat;
    // bottom: 21px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.2px;
    color: #333 !important;
}

.modal-header {
    border: none !important;
}

#bv-modal-detalhe .modal-dialog.modal-md {
    // width: 80% !important;
    max-width: inherit !important;
}

.modal-content {
    height: 98% !important;
    width: 1100px;
    margin-left: auto;
    margin-right: auto;
}

#bv-modal-detalhe .modal-dialog.modal-md {
    height: 100% !important;
    max-height: 650px;
}

#bv-modal-detalhe .modal-dialog.modal-md {
    position: relative !important;
    top: 50% !important;
    transform: translateY(-54%) !important;
}


div#modal-preview {
    width: 100%;
    height: 100%;
}

#modal-preview .modal-content{
    height: 89% !important;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

body{
    background-color: white !important;
}

.navbar {
    background: white;
    border-bottom: 1px solid lightgray;
}

.navbar-brand {
    font-size: 14px;
    color: #888;
}

#bv-modal-detalhe .modal-dialog.modal-md {
    position: relative;
    top: 45%;
    transform: translateY(-50%);
}

div#bv-modal-example {
    padding: 0px !important;
}

canvas#line-chart {
    width: 98% !important;
    height: 265px !important;
}

body{
    background-color: #fff !important;
}

.modal-header {
    border: none !important;
}

#bv-modal-detalhe .modal-dialog.modal-md {
    // width: 70% !important;
    max-width: inherit !important;
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
    color: #000;
    opacity: inherit !important;
}


button.wizard-btn {
    width: 158px;
    height: 50px;
    background: #00E4A0;
    border-radius: 3px;
}

span.stepTitle {
    display: none;
}

span.stepTitle.active {
    display: block !important;
}

span.stepTitle.active {
    display: block !important;
    position: relative;
    // left: 62%;
    font-family: Montserrat;
    // bottom: 21px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.2px;
    color: #333 !important;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
    border: none;
    background: #f5f2fc80;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #B5B9C5;
    line-height: 37px;
}

.title-open {
    white-space: nowrap !important;
}

tr.row-table-body {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #333;
    line-height: 51px;
}

.row-table-body .check {
    position: relative;
    top: 16px;
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
    display: none;
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
    display: none;
}

.pagination-sm .page-link {
    border: none !important;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #fff;
    display: flex;
    width: 0px;
}

button.page-link.flex-grow-1 {
    height: 60px;
}

.page-item.disabled .page-link {
    padding-top: 16px;
}

button.page-link.flex-grow-1 {
    height: 40px;
    width: 0px;
    outline: none;
    -webkit-box-shadow: none;
}

button.page-link.flex-grow-1:focus {
    outline: none;
    -webkit-box-shadow: none;
}

.modal-open .modal {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}

</style>